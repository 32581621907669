import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"

const RecentScholarlyImpactPage = () => (
  <Layout>
    <Seo title="Privacy Statement" />
    <div id="content">
      <div id="feature_box"></div>
      <div class="post_box top" id="post-52">
        <div class="headline_area">
          <h1>Privacy Statement</h1>
        </div>
        <div class="format_text">
          <h1>TRAUTMANSGUIDE.COM ONLINE AND OFFLINE PRIVACY STATEMENT</h1>
          <p>
            <strong>Last updated: July 31, 2010.</strong>
          </p>
          <p>
            At Trautmansguide.com we value all of our customers and understand
            that you care how information about you is used and shared, and we
            appreciate your trust that we will do so carefully and sensibly. Our
            use of the term “Trautmansguide.com” shall refer to
            Trautmansguide.com, Cogent Information Analytics, Inc., dba
            Trautman’s Guide™, present and future successors, assigns, parents
            or subsidiaries.&nbsp; The Privacy Principles set out below apply to
            COGENT INFORMATION ANALYTICS, INC., dba Trautman’s Guide™’s
            collection, storage, use and disclosure of personal information that
            may be collected by us when you interact with COGENT INFORMATION
            ANALYTICS, INC., dba Trautman’s Guide™, such as when you visit our
            stores or websites, participate in our Director’s Marketplace™ or
            publications programs, or deal with customer service. Data content
            is the essential ingredient in many mission critical areas of
            business intelligence.&nbsp; Proprietary databases power many of the
            corporate governance and strategic analytical services we offer.
            Trautmansguide.com recognizes the privacy interests of the
            enterprises and individuals on which it collects information and has
            implemented comprehensive and formal data protection policies which
            balance privacy expectations with our legitimate business
            needs.&nbsp; COGENT INFORMATION ANALYTICS, INC., dba Trautman’s
            Guide™ has created this privacy statement in order to demonstrate
            our firm commitment to privacy. COGENT INFORMATION ANALYTICS, INC.,
            dba Trautman’s Guide™ believes that concern for everyone’s privacy
            is a basis for sound business practices within direct marketing,
            client service and other arenas. This statement is intended to apply
            to all visitors of web sites owned and operated by COGENT
            INFORMATION ANALYTICS, INC., dba Trautman’s Guide™. &nbsp;If you
            have any questions after reading this privacy statement, you may
            contact us at (214) 248-3382. This notice describes our privacy
            policy.
          </p>
          <p>
            <strong>
              BY VISITING TRAUTMANSGUIDE.COM, YOU ARE ACCEPTING THE PRACTICES
              DESCRIBED IN THIS PRIVACY STATEMENT.
            </strong>
          </p>
          <p>
            Protecting the privacy and security of your personal information is
            a priority at COGENT INFORMATION ANALYTICS, INC., dba Trautman’s
            Guide™, and we do our best to ensure that your personal information
            is always handled in a safe and responsible manner in accordance
            with the following Privacy Principles:
          </p>
          <p>
            <strong>1. &nbsp;What Personal Information Do We Collect?</strong>
          </p>
          <p>
            <strong>2. &nbsp;Examples of Information Collected</strong>
          </p>
          <p>
            <strong>
              2.&nbsp; Why Do We Collect This Personal Information?
            </strong>
            <strong> </strong>
          </p>
          <p>
            <strong>
              4.&nbsp; How Does Trautmansguide.com Protect My Privacy?
            </strong>
          </p>
          <p>
            <strong>5.&nbsp; Does Trautmansguide.com Use Cookies? </strong>
          </p>
          <p>
            <strong>
              6.&nbsp; With Whom Do We Share Your Personal Information?
            </strong>
          </p>
          <p>
            <strong>7.&nbsp; How Secure Is Information About Me? </strong>
          </p>
          <p>
            <strong>
              8.&nbsp; What About Third-Party Advertisers and Links to Other
              Websites?{" "}
            </strong>
          </p>
          <p>
            <strong>9.&nbsp; Which Information Can I Access? </strong>
          </p>
          <p>
            <strong>10.&nbsp; What Choices Do I Have? </strong>
          </p>
          <p>
            <strong>
              11. Are Children Allowed to Use Trautmansguide.com?{" "}
            </strong>
          </p>
          <p>
            <strong>
              12. Trautmansguide.com Does Not Knowingly Collect Personal
              Information from Children
            </strong>
          </p>
          <p>
            <strong>13. </strong>Does Trautmansguide.com Participate in the Safe
            Harbor Program?
          </p>
          <p>
            <strong>14. Conditions of Use, Notices, and Revisions </strong>
          </p>
          <p>
            <strong>14. Examples of Information Collected</strong>
          </p>
          <p>
            <strong> </strong>
          </p>
          <p>
            <strong>1. WHAT PERSONAL INFORMATION DO WE COLLECT?</strong>
            <strong>
              <br />
            </strong>{" "}
            Personal information is information that can be used to identify,
            contact or locate you. For example, personal information can include
            your name, e-mail address, billing address, shipping address, phone
            number, or credit card information. The information compiled by
            COGENT INFORMATION ANALYTICS, INC., dba Trautman’s Guide™
            incorporates several data elements for businesses and individual
            customers. The information is acquired from reliable sources and
            COGENT INFORMATION ANALYTICS, INC., dba Trautman’s Guide™ takes
            great care through specific quality control procedures to verify and
            maintain the accuracy of the information. COGENT INFORMATION
            ANALYTICS, INC., dba Trautman’s Guide™ does not collect personally
            identifiable information about visitors except when visitors
            specifically provide such information on a voluntary basis. For
            example, such personally identifiable information may be gathered
            from the registration process for subscription sites or services and
            in connection with content submissions, community postings (e.g.,
            chat and bulletin boards), suggestions, and transactional areas.
            Such information may be used by COGENT INFORMATION ANALYTICS, INC.,
            dba Trautman’s Guide™ for the following purposes: contacting a
            subscriber regarding account status, changes to the subscriber
            agreement, special discounts, promotions, products offered and other
            matters relevant to the underlying service and/or the information
            collected.
          </p>
          <p>
            COGENT INFORMATION ANALYTICS, INC., dba Trautman’s Guide™ values
            opinions and comments from visitors, so we occasionally conduct
            online surveys. These surveys are entirely optional for all
            visitors. Most frequently, the information is aggregated and used to
            make improvements to COGENT INFORMATION ANALYTICS, INC., dba
            Trautman’s Guide™ web sites and to develop appealing content,
            features and promotions for visitors. Survey participants are
            anonymous unless otherwise stated in the survey. COGENT INFORMATION
            ANALYTICS, INC., dba Trautman’s Guide™ reserves the right to perform
            statistical analyses of visitor’s behavior and characteristics. In
            order to measure interest in and use of the various areas of the
            site and to inform advertisers of such information as well as the
            number of visitors that have been exposed to or clicked on their
            advertising banners. COGENT INFORMATION ANALYTICS, INC., dba
            Trautman’s Guide™ will provide only aggregated data from these
            analyses to third parties. Also, visitors should be aware that
            COGENT INFORMATION ANALYTICS, INC., dba Trautman’s Guide™ may
            sometimes permit third parties to offer subscription and/or
            registration-based services through a COGENT INFORMATION ANALYTICS,
            INC., dba Trautman’s Guide™ web site.
          </p>
          <p>
            COGENT INFORMATION ANALYTICS, INC., dba Trautman’s Guide™ is not
            responsible for any actions or policies of such third parties and
            visitors should check the applicable privacy policy of such party
            when providing personally identifiable information. Visitors should
            be aware that when they voluntarily disclose personally identifiable
            information (e.g., user name, e-mail address) on the bulletin boards
            or in the chat areas of COGENT INFORMATION ANALYTICS, INC., dba
            Trautman’s Guide™ sites, that information, along with any
            substantive information disclosed in the visitor’s communication,
            can be collected, correlated and used by third parties. This
            information may result in unsolicited messages from other posters or
            third parties. Such activities are beyond the control of COGENT
            INFORMATION ANALYTICS, INC., dba Trautman’s Guide™.
          </p>
          <p>
            <strong>2. </strong>
            <strong>EXAMPLES OF INFORMATION COLLECTED</strong>
          </p>
          <p>
            <strong>INFORMATION YOU GIVE US </strong>
          </p>
          <p>
            You provide most such information when you register to be included
            in one of our publications, search, buy, post, participate in a
            contest or questionnaire, or communicate with our customer service.
            For example, you provide information when you search for a product;
            place an order through Trautmansguide.com or one of our third-party
            sellers; provide information in Your Account (and you might have
            more than one if you have used more than one e-mail address when
            shopping with us) or Your Profile; communicate with us by phone,
            e-mail, or otherwise; complete a questionnaire or a contest entry
            form;&nbsp; provide employer information when opening a corporate
            account; participate in Discussion Boards or other community
            features; share information with members of a relevant community;
            and employ other Personal Notification Services. As a result of
            those actions, you might supply us with such information as your
            name, address, and phone numbers; credit card information; people to
            whom purchases have been shipped, including addresses and phone
            number; e-mail addresses of purchasers and other people; content of
            reviews and e-mails sent to us; personal description and photograph
            in your personal profile; and financial information, including
            Social Security and driver’s license numbers. You may also submit
            information to us about yourself for inclusion in one or more of our
            guides / directories (a “Biographical Submission”).&nbsp; Our use of
            “Biographical Submissions” is not governed by this Privacy
            Statement.&nbsp; Please see our Legal Information and Notices
            instead.
          </p>
          <p>
            <strong>AUTOMATIC INFORMATION</strong>
          </p>
          <p>
            Examples of the information we collect and analyze include the
            Internet protocol (IP) address used to connect your computer to the
            Internet; login; e-mail address; password; computer and connection
            information such as browser type, version, and time zone setting,
            browser plug-in types and versions, operating system, and platform;
            purchase history, which we sometimes aggregate with similar
            information from other customers to create features; the full
            Uniform Resource Locator (URL) clickstream to, through, and from our
            Web site, including date and time; cookie number; products you
            viewed or searched for; and the phone number you used to call our
            800 number. We may also use browser data such as cookies, Flash
            cookies (also known as Flash Local Shared Objects), or similar data
            on certain parts of our Web site for fraud prevention and other
            purposes. During some visits we may use software tools such as
            JavaScript to measure and collect session information, including
            page response times, download errors, length of visits to certain
            pages, page interaction information (such as scrolling, clicks, and
            mouse-overs), and methods used to browse away from the page.
          </p>
          <p>
            <strong>INFORMATION FROM OTHER SOURCES</strong>
          </p>
          <p>
            Examples of information we receive from other sources include
            updated delivery and address information from our carriers or other
            third parties, which we use to correct our records and deliver your
            next purchase or communication more easily; account information,
            purchase or redemption information, and page-view information from
            some merchants with which we operate co-branded businesses or for
            which we provide technical, fulfillment, advertising, or other
            services; search term and search result information from some
            searches conducted through the Web search features offered by our
            subsidiaries; search results and links, including paid listings
            (such as Sponsored Links); and credit history information from
            credit bureaus, which we use to help prevent and detect fraud and to
            offer certain credit or financial services to some customers.
          </p>
          <p>
            <strong>CO-BRANDED AND JOINT OFFERINGS</strong>
          </p>
          <p>
            We may choose to work with other businesses to offer joint or
            co-branded products and other offerings.
          </p>
          <p>
            <strong>INFORMATION YOU CAN ACCESS</strong>
          </p>
          <p>
            Examples of information you can access easily at Trautmansguide.com
            include up-to-date information regarding recent orders; personally
            identifiable information (including name, e-mail, password,
            communications and personalized advertising preferences, address
            book, and other settings); payment settings (including credit card
            information and gift certificate, gift card, and check balances);
            e-mail notification settings (including alerts, notifications of
            product and other special promotions, newsletters); recommendations
            (including recent product view history and prior order history).
          </p>
          <p>
            <strong> </strong>
          </p>
          <p>
            <strong>3. WHY DO WE COLLECT THIS PERSONAL INFORMATION?</strong>
            <strong>
              <br />
            </strong>{" "}
            We collect your personal information in an effort to provide you
            with a superior customer experience and, as necessary, to administer
            our business. It allows us to provide you with easy access to our
            products and services, with a particular focus on the items and
            programs that may be of most interest to you. For more information
            as to why we collect personal information, please see the section
            below entitled: “How do we use your personal information?”
          </p>
          <p>
            Your personal information also allows us to communicate with you
            about special offers, promotions, and other marketing programs and
            news that may be of interest to you.<strong> </strong>
          </p>
          <p>
            <strong> </strong>
          </p>
          <p>
            <strong>4. HOW DOES TRAUTMANSGUIDE.COM PROTECT MY PRIVACY?</strong>
          </p>
          <p>
            We at Trautmansguide.com are keenly aware of the trust our users
            place in us and our responsibility to protect their privacy.&nbsp;
            We believe the foundations of privacy are rooted in transparency and
            choice.&nbsp; To assist you in reaching informed decisions about
            your own privacy, we want to inform you of the information we
            collect when you use our services and products and how we utilize
            that information to improve your service.&nbsp; We also attempt to
            give you meaningful choices when possible about the information you
            provide to Trautmansguide.com and others.&nbsp; The information we
            learn from customers helps us personalize and continually improve
            your corporate governance, business intelligence and community
            experience at Trautmansguide.com. Here are the types of information
            we gather.
          </p>
          <p>
            <strong>Information From Public Sources:</strong> We may receive and
            store information that is available from sources in the public
            domain.&nbsp; We use this information to augment and compile data we
            publish in our products.
          </p>
          <p>
            <strong>Information You Give Us:</strong> We receive and store any
            information you enter on our Web site or give us in any other way.
            You can choose not to provide certain information, but then you
            might not be able to take advantage of many of our features. We use
            the information that you provide for such purposes as responding to
            your requests to be listed in any of our products, customizing
            future benefits for you, improving our products, and communicating
            with you.
          </p>
          <p>
            <strong>Automatic Information:</strong> When you visit our websites,
            we receive and store certain types of information whenever you
            interact with us. For example, like many Web sites, we use
            “cookies,” and we obtain certain types of information when your Web
            browser accesses Trautmansguide.com or advertisements and other
            content served by or on behalf of Trautmansguide.com on other Web
            sites.&nbsp; See, “DOES TRAUTMANSGUIDE.COM USE COOKIES?”
            <br />
            <strong>
              Information Collected Using Pixel Tags or Clear GIFs:{" "}
            </strong>
            To help us understand the effectiveness of certain of our
            communications and marketing efforts, we may use “message format”
            and “message open” sensing technologies that use pixel tags or clear
            GIFs (which are also called web beacons). These technologies allow
            us to know if your e-mail program is able to accept HTML e-mails
            and, if it is, to: (1) send you e-mails in that format; and (2)
            determine if you have opened our e-mail messages.
            <br />
            <strong>Information Collected from Outside Sources: </strong>We may
            periodically collect personal information and other information
            about you from other sources such as business partners, contractors
            and/or other third parties.
            <br />
            <strong>
              Information Collected from Visitors from Outside the United
              States:{" "}
            </strong>
            If you are visiting Trautmansguide.com from outside the United
            States and provide us with personal information, please note that
            your personal information will be transferred, stored and processed
            within the United States. The data protection laws in the United
            States may not be as comprehensive as those in your country.
          </p>
          <p>
            <strong>By doing business or interacting with </strong>
            <strong>Trautmansguide.com</strong>
            <strong>
              , you are consenting to the transfer of your personal information
              to facilities located in the United States and other facility
              locations selected by
            </strong>{" "}
            <strong>
              Cogent Information Analytics, Inc., dba Trautman’s Guide™, present
              and future successors, assigns, parents or subsidiaries
              <strong>.</strong>
            </strong>
          </p>
          <p>
            <strong> </strong>
          </p>
          <p>
            <strong>E-mail Communications:</strong> To help us make e-mails more
            useful and interesting, we often receive a confirmation when you
            open e-mail from Trautmansguide.com if your computer supports such
            capabilities. We may also compare our user list to lists received
            from other companies, in an effort to avoid sending unnecessary
            messages to our users. If you do not want to receive e-mail or other
            mail from us, please adjust your Customer Communication Preferences.
          </p>
          <p>
            <strong>5. DOES TRAUTMANSGUIDE.COM USE COOKIES?</strong>
          </p>
          <p>
            <strong> </strong>Like most search engines and web sites,
            Trautmansguide.com uses cookies to provide user services and
            advertising and to improve the quality of user experience. The use
            of cookies helps us to keep a record of your preferences, like
            whether you want your e-mail address included in our published
            Guides.&nbsp; Without the use of cookies, Trautmansguide.com would
            not be able to remember what different users like.&nbsp; Cookies
            also help us to provide advertisement offerings more relevant to
            your interests.
          </p>
          <p>
            <strong>Cookies Defined: </strong>A cookie is a small file
            containing alphanumeric identifiers that we transfer to your
            computer’s hard drive through your Web browser to enable our systems
            to recognize your browser and to provide features such as automated
            purchasing, personalized advertisements on other Web sites (e.g.,
            any site having content served by Trautmansguide.com and Web sites
            using our affiliated payment service), and storage of items in your
            Shopping Cart between visits.
          </p>
          <p>
            <strong>Accepting New Cookies: </strong>You can reset your browser
            to refuse all cookies or to indicate when a cookie is being
            sent.&nbsp; However, some website services or features may not
            function properly without cookies.&nbsp; The Help portion of the
            toolbar on most browsers will tell you how to prevent your browser
            from accepting new cookies, how to have the browser notify you when
            you receive a new cookie, or how to disable cookies altogether.
            Additionally, you can disable or delete similar data used by browser
            add-ons, such as Flash cookies, by changing the add-on’s settings or
            visiting the Web site of its manufacturer. However, because cookies
            allow you to take advantage of some of Trautmansguide.com’s
            essential features, we recommend that you leave them turned on. For
            instance, if you block or otherwise reject our cookies, you will not
            be able to add items to your Shopping Cart, proceed to Checkout, or
            use any Trautmansguide.com products and services that require you to
            Sign in.
          </p>
          <p>
            <em>
              <strong>Site Analytics</strong>
            </em>
            <strong>
              <em>:</em>
            </strong>{" "}
            We work with a third party service provider to help us better
            understand how you use our Trautmansguide.com websites. This third
            party service provider will place cookies on your computer to
            collect information. The information this third party service
            provider collects will tell us things like which search engine
            referred you to our websites, how you navigated around our websites,
            what you purchased and what traffic is driven by banner ads and
            emails. This information will help us to better serve you and
            provide you with more personalized information and product
            offerings. We do not allow this third party service provider to
            collect your credit card information, e-mail address or password
            information. This third party service provider may only use your
            personal information to perform services for us. This third party
            service provider is contractually required to maintain all of the
            information it collects and analyzes for us securely and in
            confidence. This third party service provider may not share your
            personal information with anyone else, or use it for any other
            purpose, except on an aggregate, non-personal basis.
            <br />
            Please note that this third party service provider collects data on
            our Trautmansguide.com&nbsp; websites using first-party data
            collection. As a result, blocking cookies from third-party domains
            will not block these first-party cookies. As a visitor to
            Trautmansguide.com, you may choose to opt-out of this third party’s
            analysis of your browsing and purchasing behavior on our websites.
            To opt-out on Trautmansguide.com please click here.
            <br />
            In addition, third party distributors of banner ads on our websites
            may also place or access cookies on your computer. If you would like
            to learn more about the third party distributors of banner ads on
            any Trautmansguide.com website, and to understand your choices about
            having such distributors’ cookies turned off, please visit
            www.networkadvertising.org. Please understand that
            Trautmansguide.com does not control these third party cookies, and
            you should check the privacy policy of the Internet advertising
            company or advertiser to see whether and how it uses cookies.
            <br />
            Whenever you click on links and banners on any Trautmansguide.com
            website that take you to a third party website, you will be subject
            to the third party’s privacy policies, not Trautmansguide.com’s.
            This Privacy Policy applies solely to information collected by
            Trautmansguide.com.
          </p>
          <p>
            <strong>6. WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?</strong>
          </p>
          <p>
            Information about our customers and information regarding relevant
            communities is an important part of our business. We share customer
            information only as described below and with subsidiaries or
            affiliates of Cogent Information Analytics, Inc., dba Trautman’s
            Guide™, present and future successors, assigns, parents or
            subsidiaries. COGENT INFORMATION ANALYTICS, INC., dba Trautman’s
            Guide™ complies with all applicable federal and state laws
            concerning data privacy, as well as best practices and procedures
            and guidelines established by industry groups of which the company
            may be a member. COGENT INFORMATION ANALYTICS, INC., dba Trautman’s
            Guide™ maintains agreements with its customers containing strict
            controls on the data as required by the applicable laws and industry
            standards.
          </p>
          <p>
            <strong>
              Affiliated Businesses We Do Not Control, Such As Service
              Providers:
            </strong>{" "}
            We work closely with affiliated businesses. In some cases these
            businesses operate stores at Trautmansguide.com or sell offerings to
            you at Trautmansguide.com. In other cases of co-branded and joint
            offerings, we operate stores, provide services, or sell product
            lines jointly with these businesses. You can tell when a third party
            is involved in your transactions, and we share customer information
            related to those transactions with that third party. We may share
            Personally Identifiable Information with third parties, who may
            contact you to offer products and services.&nbsp; At any time, you
            can contact trautmansguide.com by sending an e-mail to
            optout@trautmansguide.com to request that we not share your personal
            information with third parties.
          </p>
          <p>
            <strong>Third-Party Service Providers:</strong> We employ other
            companies and individuals to perform functions on our behalf. These
            are third party providers of products and services you may purchase
            from or through us (i.e. corporate governance materials). These
            third parties do not have the right to use personal information we
            provide to them in any way that is not authorized by COGENT
            INFORMATION ANALYTICS, INC., dba Trautman’s Guide™.&nbsp; They are
            contractually obligated to use such information only as necessary to
            assist us and to maintain the confidentiality and security of such
            information.Examples include fulfilling orders, delivering packages,
            sending postal mail and e-mail, removing repetitive information from
            customer lists, analyzing data, providing marketing and product
            production assistance, providing search results and links (including
            paid listings and links), processing credit card payments, and
            providing customer service. They have access to personal information
            needed to perform their functions, but may not use it for other
            purposes.
          </p>
          <p>
            <strong>Third Party Membership Programs:</strong> We may work with
            certain third party entities to help them administer their own
            membership or rewards programs by providing them with purchasing
            information about their customers who make purchase from COGENT
            INFORMATION ANALYTICS, INC., dba Trautman’s Guide™. &nbsp;We
            disclose only the information that is necessary to make these third
            party programs work and to support your membership in them. This
            information usually includes your name and e-mail address as well as
            the dollar amount of the purchases made at COGENT INFORMATION
            ANALYTICS, INC., dba Trautman’s Guide™. &nbsp;We require such
            entities to obtain your consent before we provide them with this
            information, which they usually do as part of their own membership
            or participation rules.
          </p>
          <p>
            <strong>Credit Card Companies: </strong>Credit card transactions are
            handled by third party financial institutions and their vendors and
            contractors who receive credit card numbers and other personal
            information from us to verify the credit card numbers and process
            transactions.
          </p>
          <p>
            <strong>Law enforcement officials and as required by law: </strong>
            COGENT INFORMATION ANALYTICS, INC., dba Trautman’s Guide™ may
            release personal information to third parties when we determine, in
            our judgment, that it is necessary to (a) comply with the law,
            regulation, legal process, or enforceable governmental request; (b)
            enforce or apply the terms of any of our policies or user
            agreements; or (c) protect the rights, property or safety of COGENT
            INFORMATION ANALYTICS, INC., dba Trautman’s Guide™, its successors,
            assigns, parents, subsidiaries and affiliates, including Trautman’s
            Guide™ sites around the world (collectively, the “Site”)., our
            employees, our customers, users, or others.
          </p>
          <p>
            <strong>Promotional Offers:</strong> Sometimes we send offers to
            selected groups of Trautmansguide.com customers on behalf of other
            businesses. When we do this, we do not give that business your name
            and address. If you do not want to receive such offers, please
            adjust your Customer Communication Preferences.
          </p>
          <p>
            <strong>Business Transfers:</strong> As we continue to develop our
            business, we might sell or buy products, websites, stores,
            subsidiaries, or business units. In such transactions, customer
            information generally is one of the transferred business assets but
            remains subject to the promises made in any pre-existing Privacy
            Notice (unless, of course, the customer consents otherwise). Also,
            in the unlikely event that Trautmansguide.com, Inc., or
            substantially all of its assets are acquired, customer information
            will of course be one of the transferred assets.
          </p>
          <p>
            <strong>Sales, Mergers, and Acquisitions</strong>
            <strong>:</strong> If COGENT INFORMATION ANALYTICS, INC., dba
            Trautman’s Guide™ becomes involved in a merger, acquisition, or any
            form of sale of some or all of its assets, personal information may
            be provided to the entities and advisors involved subject to a
            confidentiality agreement, and we will provide notice before any
            personal information is finally transferred and becomes subject to a
            different privacy policy.
          </p>
          <p>
            <strong>Protection of Trautmansguide.com and Others:</strong> We
            release account and other personal information when we believe
            release is appropriate to comply with the law; enforce or apply our
            Conditions of Use and other agreements; or protect the rights,
            property, or safety of Trautmansguide.com, our users, or others.
            This includes exchanging information with other companies and
            organizations for fraud protection and credit risk reduction.
            Obviously, however, this does not include selling, renting, sharing,
            or otherwise disclosing personally identifiable information from
            customers for commercial purposes in violation of the commitments
            set forth in this Privacy Notice.
          </p>
          <p>
            <strong>Purchase of Certain Digital Content: </strong>Please be
            aware that in connection with purchases of certain Digital Content
            through websites operated by COGENT INFORMATION ANALYTICS, INC., dba
            Trautman’s Guide™, its successors, assigns, parents, subsidiaries
            and affiliates, including Trautman’s Guide™ sites around the world
            (collectively, the “Site”)., we may need to forward information
            about you (including, for example, your Internet Protocol (IP)
            address) to the digital content provider in order to enable you to
            download or purchase Digital Content from or through that provider.
            In each such instance, the Digital Content provider is obligated to
            use such information in accordance with its own privacy policy and
            applicable law.
          </p>
          <p>
            <strong>Purchase of Certain Publications and Subscriptions:</strong>{" "}
            Please be aware that in connection with some magazine or newspaper
            subscriptions and single issue purchases of magazines or newspapers
            placed on Trautmansguide.com or another of our affiliated sites, in
            order to complete your transaction, we may need to forward your
            name, magazine or newspaper order, email address, and shipping or
            billing address to our content providers. The content providers will
            share your magazine or newspaper order information as well as your
            name and address, or name and e-mail and shipping or billing
            addresses with the magazine or newspaper publisher and magazine or
            newspaper circulation auditors. Magazine and newspaper publishers
            may use this information to fulfill your order and for other
            purposes. Your credit card information will not be shared with them.
          </p>
          <p>
            <strong>With Your Consent:</strong> Other than as set out above, you
            will receive notice when information about you might go to third
            parties, and you will have an opportunity to choose not to share the
            information.
          </p>
          <p>
            <strong>7. &nbsp;HOW SECURE IS INFORMATION ABOUT ME?</strong>
          </p>
          <p>
            COGENT INFORMATION ANALYTICS, INC., dba Trautman’s Guide™ uses
            several security systems and procedures to safeguard the
            information, ensuring the data is accessed only by authorized
            individuals for legitimate business needs. COGENT INFORMATION
            ANALYTICS, INC., dba Trautman’s Guide™ will not transmit or sell the
            compiled information to third parties without obtaining a written or
            electronic agreement that defines the rights and responsibilities
            with respect to the data. Further, COGENT INFORMATION ANALYTICS,
            INC., dba Trautman’s Guide™ will review our customer’s use of the
            data to ensure sensitive data is being handled in accordance with
            required protections, and that this information is not used for
            deceptive, misleading, distasteful, or suspicious purposes. COGENT
            INFORMATION ANALYTICS, INC., dba Trautman’s Guide™ is responsible
            for the security of personal information provided by visitors.
            Strict measures will be taken to assure against unauthorized access,
            alteration, or dissemination of personal information. COGENT
            INFORMATION ANALYTICS, INC., dba Trautman’s Guide™ is responsible
            for the security of personal information provided by visitors.
            Strict measures will be taken to assure against unauthorized access,
            alteration, or dissemination of personal information.
          </p>
          <p>
            (A). We work to protect the security of your information during
            transmission by using Secure Sockets Layer (SSL) software, which
            encrypts information you input.
          </p>
          <p>
            (B). We reveal only the last four digits of your credit card numbers
            when confirming an order. Of course, we transmit the entire credit
            card number to the appropriate credit card company during order
            processing.
          </p>
          <p>
            (C). It is important for you to protect against unauthorized access
            to your password and to your computer. Be sure to sign off when
            finished using a shared computer. Click here for more information on
            how to sign off.
          </p>
          <p>
            <strong>
              8. &nbsp;WHAT ABOUT THIRD-PARTY ADVERTISERS AND LINKS TO OTHER
              WEBSITES?
            </strong>
            <br />
            Our site(s) may include third-party advertising and links to other
            Web sites. We do not provide any personally identifiable customer
            information to these advertisers or third-party Web sites. Click
            here for more information about our Advertising Policies and
            Specifications.
            <br />
            These third-party Web sites and advertisers, or Internet advertising
            companies working on their behalf, sometimes use technology to send
            (or “serve”) the advertisements that appear on our Web site directly
            to your browser. They automatically receive your IP address when
            this happens. They may also use cookies, JavaScript, web beacons
            (also known as action tags or single-pixel gifs), and other
            technologies to measure the effectiveness of their ads and to
            personalize advertising content. We do not have access to or control
            over cookies or other features that they may use, and the
            information practices of these advertisers and third-party Web sites
            are not covered by this Privacy Notice. Please contact them directly
            for more information about their privacy practices. In addition, the{" "}
            <a href="http://www.networkadvertising.org/">
              Network Advertising Initiative
            </a>{" "}
            offers useful information about Internet advertising companies (also
            called “ad networks” or “network advertisers”), including
            information about how to opt-out of their information collection.
            <br />
            Trautmansguide.com also displays personalized third-party
            advertising based on personal information about customers, such as
            purchases on Trautmansguide.com, visits to affiliated Web sites, or
            use of affiliated payment services on other Web sites. Click here
            for more information about the personal information that we gather.
            Although Trautmansguide.com does not provide any personal
            information to advertisers, advertisers (including ad-serving
            companies) may assume that users who interact with or click on a
            personalized advertisement meet their criteria to personalize the ad
            (for example, users in the Southern United States who bought one of
            our corporate governance products). If you do not want us to use
            personal information that we gather to allow third parties to
            personalize advertisements we display to you, please adjust your
            Advertising Preferences.
          </p>
          <p>
            <strong>9. &nbsp;WHICH INFORMATION CAN I ACCESS?</strong>
            <br />
            Trautmansguide.com gives you access to a broad range of information
            about your account and your interactions with Trautmansguide.com for
            the limited purpose of viewing and, in certain cases, updating that
            information. Click here to see some examples, the list of which will
            change as our Web site evolves.
          </p>
          <p>
            <strong>10. &nbsp;WHAT CHOICES DO I HAVE?</strong>
          </p>
          <p>
            Upon a reasonable request, COGENT INFORMATION ANALYTICS, INC., dba
            Trautman’s Guide™ will explain to businesses and consumers the
            nature and types of sources used to compile the data. COGENT
            INFORMATION ANALYTICS, INC., dba Trautman’s Guide™ maintains
            procedures by which businesses can request corrections for any
            incorrect data, as well as request any business or consumer
            information be suppressed from compiled lists which are marketed to
            COGENT INFORMATION ANALYTICS, INC., dba Trautman’s Guide™ customers.
            Requests for information or access can be made in writing to COGENT
            INFORMATION ANALYTICS, INC., dba Trautman’s Guide™, &nbsp;Attn:
            Content Feedback, P.O. Box 796216, Dallas, TX&nbsp; 75379 or
            contentfeedback@trautmansguide.com.
          </p>
          <p>
            <strong>Opt Out Policy</strong>
          </p>
          <p>
            Upon a visitor’s written request, COGENT INFORMATION ANALYTICS,
            INC., dba Trautman’s Guide™ will:
          </p>
          <ol type="i">
            <li>
              {" "}
              allow any visitor to “opt out” of further promotional contacts;
            </li>
            <li>
              {" "}
              use reasonable efforts to allow visitors to update/correct
              personal information previously submitted which he/she states is
              erroneous to the extent such activities will not compromise
              privacy or security interests; and
            </li>
            <li>
              {" "}
              initiate reasonable efforts to functionally delete the visitor and
              his/her personal information from its database. Visitors should be
              aware that it might be impossible to completely delete erroneous
              information or a visitor’s personal information without some
              residual information remaining in its database because of backups
              and records of deletions.
            </li>
          </ol>
          <p>
            (A).&nbsp; As we have discussed above, you can always choose not to
            provide information, even though it might be needed to make a
            purchase or to take advantage of such Trautmansguide.com features as
            our free directory listings or the Director’s Marketplace™.
          </p>
          <p>
            (B).&nbsp; You can add or update certain information on pages such
            as those referenced in the “Which Information Can I Access?”
            section. When you update information, we usually keep a copy of the
            prior version for our records.
          </p>
          <p>
            (C).&nbsp; If you do not want to receive e-mail or other mail from
            us, please adjust your Customer Communication Preferences. (If you
            do not want to receive Conditions of Use and other legal notices
            from us, such as this Privacy Notice, those notices will still
            govern your use of Trautmansguide.com, and it is your responsibility
            to review them for changes.)
          </p>
          <p>
            (D).&nbsp; If you do not want us to use personal information that we
            gather to allow third parties to personalize advertisements we
            display to you, please adjust your Advertising Preferences.
          </p>
          <p>
            (E).&nbsp; The Help portion of the toolbar on most browsers will
            tell you how to prevent your browser from accepting new cookies, how
            to have the browser notify you when you receive a new cookie, or how
            to disable cookies altogether. Additionally, you can disable or
            delete similar data used by browser add-ons, such as Flash cookies,
            by changing the add-on’s settings or visiting the Web site of its
            manufacturer. However, because cookies allow you to take advantage
            of some of Trautmansguide.com’s essential features, we recommend
            that you leave them turned on. For instance, if you block or
            otherwise reject our cookies, you will not be able to add items to
            your Shopping Cart, proceed to Checkout, or use any
            Trautmansguide.com products and services that require you to Sign
            in.
          </p>
          <p>
            <strong>
              11. &nbsp;ARE CHILDREN ALLOWED TO USE TRAUTMANSGUIDE.COM?
            </strong>
            <br />
            Trautmansguide.com does not sell products for purchase by children.{" "}
          </p>
          <p>
            <strong>
              12. &nbsp;TRAUTMANSGUIDE.COM DOES NOT KNOWINGLY COLLECT PERSONAL
              INFORMATION FROM CHILDREN{" "}
            </strong>
          </p>
          <p>
            Our products and services are marketed for and directed towards
            purchase by adults or with the consent of adults. Individuals under
            the age of 18 (“Minors”) are not permitted to use Trautmansguide.com
            websites without the supervision of a parent or legal guardian.
            Furthermore, we do not knowingly collect or solicit personal
            information from children under the age of 13 or knowingly allow
            such persons to register for an online account or to post personal
            information on our websites. Should we learn that someone under the
            age of 13 has provided any personal information to or on any of the
            Trautmansguide.com websites, we will remove that information as soon
            as possible.<strong> </strong>
          </p>
          <p>
            <strong> </strong>
          </p>
          <p>
            <strong>
              13. &nbsp;DOES TRAUTMANSGUIDE.COM PARTICIPATE IN THE SAFE HARBOR
              PROGRAM?
            </strong>
            <br />
            Trautmansguide.com, new website, will seek to become a participant
            in the Safe Harbor program developed by the U.S. Department of
            Commerce and the European Union. We intend to certify that we adhere
            to the Safe Harbor Privacy Principles agreed upon by the U.S. and
            the E.U. For more information about the Safe Harbor and to view our
            certification, visit the{" "}
            <a href="http://www.export.gov/safeharbor/">
              U.S. Department of Commerce’s Safe Harbor
            </a>{" "}
            Web site. Our “Safe Harbor Privacy Policy” follows below:
          </p>
          <p>Trautmansguide.com Safe Harbor Privacy Policy</p>
          <p>
            <strong>Introduction and Policy Statement</strong>
            <br />
            COGENT INFORMATION ANALYTICS, INC., dba Trautman’s Guide™ (“Cogent”
            or “Trautmansguide™”) respects individual privacy and strives to
            collect, use and disclose Personal Data in a manner consistent with
            the laws of the countries in which it does business, and prides
            itself on upholding the highest ethical standards in its business
            practices. Cogent is engaged in the business of Mission Critical
            Business Intelligence™ through employment of certain proprietary
            databases.&nbsp; These databases seek to capture detailed
            information on certain individuals, businesses and statistics useful
            to the efficient conduct of corporate governance in the United
            States and other parts of the World. All of the above databases are
            compiled by our employees and sub-contractors. We compile and update
            the databases from thousands of public sources such as yellow page
            directories, white page directories, newspapers, incorporation
            records, direct input from individuals who are the subjects of these
            databases, information in the public domain as filed with certain
            regulatory bodies and various other sources. In addition, Cogent may
            provide data processing and analytic services for its customers.
            This Safe Harbor Privacy Policy (the “Policy”) sets forth the
            privacy principles that Cogent follows with respect to Personal Data
            transferred from the European Union (EU) to the United States.
            Accordingly, Cogent will adhere to the Safe Harbor Principles and
            Frequently Asked Questions published by the U.S. Department of
            Commerce (collectively referred to as the “Principles”) at
            http://export.gov/safeharbor/ with respect to all such data, and
            intends to self-certify to the U.S. Department of Commerce
            compliance with the Principles. If there is any conflict between the
            policies in this statement and the Principles, the Principles will
            govern. This statement outlines the general policy and practices for
            implementing the Principles, including the types of information
            Cogent gathers, how the information is used, and the choices
            affected individuals have regarding Cogent’s use of, and their
            ability to correct, that information.
          </p>
          <p>
            <strong>Background</strong>
            <br />
            The EU adopted the Directive on Data Protection (“EU Directive”),
            which requires EU member states to adopt laws protecting Personal
            Data collected within their borders. These laws must, among other
            provisions, restrict the transfer of Personal Data only to countries
            that have data protection laws deemed “adequate” under standards
            established in the EU Directive. The U.S. Department of Commerce and
            the European Commission have agreed on the Principles to enable U.S.
            Companies to satisfy the requirement under EU law that adequate
            protection be given to Personal Data transferred from the EU to the
            U.S.
          </p>
          <p>
            <strong>Definitions</strong>
          </p>
          <p>
            <strong>“Cogent”</strong> – means COGENT INFORMATION ANALYTICS,
            INC., dba Trautman’s Guide™, a Texas corporation, and its domestic
            and foreign subsidiaries, divisions, groups, and affiliates.
          </p>
          <p>
            “<strong>Identifiable Person</strong>” – means a natural person that
            is or can be identified, directly or indirectly, as a particular
            person by reference to an identification number or to one or more
            aspects of the person’s physical, physiological, mental, economic,
            cultural or social identity. Identifiable Persons may include any
            employee, applicant, former employee, or retiree of Cogent, its
            operating divisions, or subsidiaries in the EU.
          </p>
          <p>
            <strong>“Personal Data”</strong> – is any information about an
            Identifiable Person that
          </p>
          <ul>
            <li>is within the scope of the EU Directive;</li>
            <li>is received by Cogent in the U.S. from the EU;</li>
            <li>is recorded in any form;</li>
            <li>is about, or pertains to, a specific individual; and</li>
            <li>can be linked to that individual.</li>
          </ul>
          <p>
            Personal Data does not include information that is encoded,
            anonymized, or publicly available information that has not been
            combined with non-public Personal Data.
          </p>
          <p>
            <strong>“Processing”</strong> – means any online, offline or manual
            processing and includes such activities as copying, filing, and
            inputting Personal Data into a database.
          </p>
          <p>
            <strong>“Sensitive Data”</strong> – is data that pertains to medical
            or health conditions, racial or ethnic origin, political opinions,
            religious or philosophical beliefs, trade union membership, sexual
            orientation or any other data that is identified as “sensitive” by
            the Identifiable Person.
          </p>
          <p>
            <strong>Publication</strong>
            <br />
            This Privacy Policy will be published on the web at
            http://www.trautmansguide.com
          </p>
          <p>
            <strong>Guidelines</strong>
          </p>
          <p>
            <strong>1. Notice </strong>
            <br />
            Where Cogent collects Personal Data directly from Identifiable
            Persons in the EU, it will inform them about the type of Personal
            Data collected, the purposes for which it collects and uses the
            Personal Data, the types of non-agent third parties to which Cogent
            discloses or may disclose that information, and the choices and
            means, if any, Cogent offers individuals for limiting the use and
            disclosure of their Personal Data. Notice will be provided in a
            clear and conspicuous language when individuals are first asked to
            provide Personal Data to Cogent, or as soon as practicable
            thereafter, and in any event before Cogent uses or discloses the
            information for a purpose other than that for which it was
            originally collected. Where Cogent receives Personal Data from their
            subsidiaries or operating divisions in the EU, it will use and
            disclose such information in accordance with the notices provided by
            such entities and the choices made by the individuals to which the
            Personal Data relates.
          </p>
          <p>
            To the extent practical and appropriate, Cogent collects Personal
            Data directly from the Identifiable Person. In those cases where
            Cogent collects Personal Data from other persons, it takes measures
            to respect the privacy preferences of the Identifiable Persons.
            Examples of when Cogent may seek information from others include,
            without limitation, evaluating employees, recruiting, benefit
            administration and succession planning.
          </p>
          <p>
            <strong>2. Choice </strong>
            <br />
            <strong>Opt-Out Rights.</strong> Cogent will offer Identifiable
            Person(s) the opportunity to choose (opt-out) whether their Personal
            Data is (a) to be disclosed to a non-agent third party or (b) to be
            used for a purpose other than the purpose for which it was
            originally collected or subsequently authorized by the individual.
            In addition, where consent of Identifiable Persons or their
            representatives is required by law, contract or agreement for the
            collection, use, or disclosure of Personal Data, Cogent will request
            such consent and respect the Identifiable Person’s choice in such
            matters.
          </p>
          <p>
            In certain limited or exceptional circumstances, in accordance with
            the Safe Harbor Principles, Cogent may disclose Personal Data
            without notice or the consent of the Identifiable Person. For
            example, this may occur when Cogent is required to disclose
            information by law or legal process or in the vital interests of the
            Identifiable Person, such as when life or health are at stake.
          </p>
          <p>
            <strong>Opt-In Requirement.</strong> Except as provided by the Safe
            Harbor Principles or applicable law, an Identifiable Person must
            give affirmative permission (opt in consent) before Cogent will
            disclose Sensitive Data to a third party or use Sensitive Data for a
            purpose other than those for which it was originally collected or
            subsequently authorized by the Identifiable Person. Cogent will
            provide Identifiable Persons with reasonable mechanisms to exercise
            their choices.
          </p>
          <p>
            <strong>3. Onward Transfer</strong>
            <br />
            Cogent may transfer Personal Data across state and country borders.
            Cogent will comply with the provisions of this Privacy Policy in any
            such transfer.
          </p>
          <p>
            <strong>4. Security</strong>
            <br />
            Cogent takes commercially reasonable precautions to protect Personal
            Data against loss, misuse and unauthorized access, disclosure,
            alteration, destruction and theft. These precautions include
            password protections for online information systems and restricted
            access to Personal Data. Employees are responsible for assisting to
            maintain security through safeguarding Personal Data, e.g., by
            protecting passwords used to access the Cogent computer systems, by
            keeping paper records under lock and key when not in use, and by
            disposing of files and reports no longer needed in a secure manner.
          </p>
          <p>
            <strong>5. Data Integrity</strong>
            <br />
            Cogent takes reasonable steps to keep Personal Data accurate,
            complete, and up-to-date. Each Identifiable Person is responsible
            for informing Cogent or its EU subsidiaries of any changes in
            Personal Data so that the information that Cogent possesses about
            him or her is accurate, complete and up-to-date. Cogent retains
            Personal Data only as long as necessary to meet the purposes for
            which it was collected or as required by law, contractual agreement
            or the Safe Harbor Principles. Cogent uses reasonable procedures,
            following retention guidelines, to ensure that it archives or
            destroys Personal Data no longer required for the purposes for which
            it was originally collected, unless otherwise agreed to by the
            Identifiable Person.
          </p>
          <p>
            <strong>6. Access</strong>
            <br />
            Cogent provides Identifiable Persons with a reasonable opportunity
            to examine their Personal Data, to challenge its accuracy and to
            have it corrected, amended or deleted as appropriate, subject to
            certain exceptions. Upon request, Identifiable Persons will be given
            reasonable access to the Personal Data Cogent possesses about them.
            Reasonable access means that requests for access are made during
            normal business hours, following Cogent standard procedures, and
            that the frequency of access requests is not excessive. If an
            Identifiable Person is denied access to Personal Data, Cogent will
            provide such Identifiable Person with the reason(s) for denying
            access and a contact point for further inquiries. If the
            Identifiable Person notifies Cogent that the Personal Data on file
            is incorrect and provides Cogent with appropriate supporting
            documentation, Cogent will either correct the Personal Data or
            direct the Identifiable Person to the source of the information for
            correction. If, upon review, Cogent believes that the existing
            Personal Data is correct, Cogent will inform the Identifiable
            Person. If the Identifiable Person continues to dispute the accuracy
            of the Personal Data, Cogent will note that dispute in the record of
            the Identifiable Person contained in the Cogent database upon
            written request.
          </p>
          <p>
            The Safe Harbor Principles provides for some exceptions to the
            obligation to provide access to Personal Data. Access to
            confidential or proprietary information, such as business
            reorganization or succession plans, or where granting access has to
            be balanced against the privacy interests of others, may be
            restricted. In addition, access may be denied
          </p>
          <ul>
            <li>
              when the information requested relates to an ongoing
              investigation, litigation or potential litigation,
            </li>
            <li>
              where the burden or expense of providing access would be
              disproportionate to the risks to the privacy of the Identifiable
              Person or
            </li>
            <li>
              when the rights of persons other than the Identifiable Person
              would be violated.
            </li>
          </ul>
          <p>
            <strong>7. Enforcement and Dispute Resolution </strong>
            <br />
            Identifiable Persons may contact the Legal Department at Cogent ‘s
            Corporate Headquarters in Dallas, Texas, U.S.A. to submit data
            access requests, register complaints or address any other relevant
            issues under the Safe Harbor Principles. It is the responsibility of
            all employees to act in accordance with the Privacy Policy and
            obligations with respect to Personal Data. Failure to do so may
            result in disciplinary action, if warranted, up to and including
            termination of employment. Cogent is committed to assisting
            Identifiable Persons in protecting their privacy and in exercising
            their rights under this Privacy Policy and applicable laws.
            Identifiable Persons making complaints or reporting potential
            violations of the Privacy Policy shall not be subject to any form of
            retaliation. In addition, report of potential violations may be made
            on an anonymous basis. For complaints that cannot be resolved
            between Cogent and the complainant, Cogent commits to cooperate with
            the Data Protection Authorities (DPAs) of the EU countries in the
            investigation and resolution of complaints and will comply with any
            advice given by DPAs.
          </p>
          <p>
            <strong>8. Verification</strong>
            <br />
            Cogent ‘s privacy practices are self-certified annually to the U.S.
            Department of Commerce. The Chief Administrative Officer is
            responsible for:
          </p>
          <ul>
            <li>
              Ensuring that the privacy guidelines, programs, procedures,
              training and other measures necessary to implement the Privacy
              Policy are developed and put into practice;
            </li>
            <li>
              Overseeing responses to inquiries and resolutions of complaints
              relating to the privacy of Identifiable Persons;
            </li>
            <li>
              Working with Cogent ‘s legal department to ensure Cogent’s ongoing
              compliance with applicable privacy laws and agreements, as well as
              any obligations Cogent may enter into voluntarily, such as the
              Safe Harbor Principles; and
            </li>
            <li>
              Overseeing annual assessments of Cogent internal practices to
              ensure that they conform to the Privacy Policy and related company
              obligations.
            </li>
          </ul>
          <p>
            <strong>Changes to this Safe Harbor Privacy Policy</strong>
            <br />
            This policy may be amended from time to time, consistent with the
            requirements of the Safe Harbor Principles. A notice will be posted
            on Cogent’s web page www.trautmansguide.com.
          </p>
          <p>
            <strong>EFFECTIVE DATE: July 31, 2010</strong>
          </p>
          <p>
            If you would like to contact Trautmansguide.com directly about the
            Safe Harbor program, please send an e-mail to
            safeharbor@Trautmansguide.com.
          </p>
          <p>
            <strong>14. CONDITIONS OF USE, NOTICES, AND REVISIONS</strong>
            <br />
            If you choose to visit Trautmansguide.com, your visit and any
            dispute over privacy is subject to this Notice and our Conditions of
            Use, including limitations on damages, resolution of disputes, and
            application of the law of the state of Washington. If you have any
            concern about privacy at Trautmansguide.com, please contact us with
            a thorough description, and we will try to resolve it. Our business
            changes constantly, and our Privacy Notice and the Conditions of Use
            will change also. We may e-mail periodic reminders of our notices
            and conditions, unless you have instructed us not to, but you should
            check our Web site frequently to see recent changes. Unless stated
            otherwise, our current Privacy Notice applies to all information
            that we have about you and your account. We stand behind the
            promises we make, however, and will never materially change our
            policies and practices to make them less protective of customer
            information collected in the past without the consent of affected
            customers.
          </p>
          <p>
            <strong>Changes</strong>
          </p>
          <p>
            COGENT INFORMATION ANALYTICS, INC., dba Trautman’s Guide™ Inc.
            reserves the right to change this privacy statement at anytime upon
            providing notification to visitors. This privacy statement and the
            policies outlined herein are not intended to and do not create any
            contractual or other legal rights in or on behalf of any other
            person or entity. We do not undertake to provide you with personal
            notice of any changes.&nbsp; In the event of material changes, we
            will provide notice by means that are reasonable under the
            circumstances, such as by posting a notice on COGENT INFORMATION
            ANALYTICS, INC., dba Trautman’s Guide™’s web page,
            http://www.trautmansguide.com. Your continued use of the website
            following the posting of changes to this Privacy Statement means
            that you accept those changes.
          </p>
          <p>
            <strong>EFFECTIVE DATE: July 31, 2010 </strong>
          </p>
        </div>
      </div>
    </div>
  </Layout>
)

export default RecentScholarlyImpactPage
